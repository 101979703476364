@font-face {
  font-family: lato;
  font-weight: 200;
  src: url(../fonts/Lato-Light.ttf);
}
@font-face {
  font-family: lato;
  font-weight: 400;
  src: url(../fonts/Lato-Medium.ttf);
}
@font-face {
  font-family: staatliches;
  font-weight: 400;
  src: url(../fonts/Staatliches-Regular.ttf);
}
html {
  box-sizing: border-box;
  font-size: 14px;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a, a:hover, a:visited {
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
}

ul {
  list-style: none;
}

button:focus {
  outline: 0;
}

body {
  padding: 0 16px;
  background: #131313;
  color: #acacac;
  font-family: lato;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  overflow-x: hidden;
}
@media screen and (min-width: 550px) {
  body {
    padding: 0 32px;
  }
}

header {
  min-height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
@media screen and (min-width: 550px) {
  header {
    padding-left: 120px;
    padding-right: 16px;
  }
}
@media screen and (min-width: 1100px) {
  header:after {
    content: "Hey !";
    display: block;
    z-index: -1;
    position: absolute;
    color: #ffffff15;
    font-family: staatliches;
    font-size: 156px;
    line-height: 156px;
    width: calc(100vh - 144px);
    overflow: hidden;
    text-overflow: clip;
    bottom: 16px;
    right: -32px;
    transform-origin: bottom left;
    transform: translateX(calc(100vh - 144px)) rotateZ(-90deg);
  }
}

nav {
  cursor: pointer;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 16px;
  font-family: staatliches;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 1.1428rem;
  line-height: 24px;
  color: #7f7f7f;
  box-shadow: 0 -1px 5px 0 #00000015;
  transition: all 0.15s;
  background-color: black;
}
nav ul {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
@media screen and (min-width: 550px) {
  nav {
    padding: 0;
    top: 0;
    left: 0;
    bottom: auto;
    margin: 16px 0 0 16px;
    width: 100px;
    height: auto;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
  nav ul {
    display: block;
  }
}

.nav_el {
  position: relative;
  display: block;
  transition: font-size 0.2s ease-in-out, font-weight 0.2s ease-in-out, color 0.2s;
}
@media screen and (min-width: 550px) {
  .nav_el {
    padding: 0;
  }
  .nav_el:hover {
    color: #E6E6E6;
  }
  .nav_el:not(:first-child) {
    margin-top: 5.3333333333px;
  }
}

.nav_el.selected {
  display: block;
  color: #E6E6E6;
}
@media screen and (min-width: 550px) {
  .nav_el.selected {
    font-size: 1.43rem;
    line-height: 24px;
  }
}

.langChoice {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  font-size: 1.43rem;
  line-height: 24px;
  padding: 24px 16px 0 0;
}
@media screen and (min-width: 550px) {
  .langChoice {
    padding: 24px 32px 0 0;
  }
}

.langChoice_el {
  font-family: staatliches;
  letter-spacing: 1px;
  font-weight: 400;
  color: #7f7f7f !important;
}

.langChoice_el.selected {
  color: #E6E6E6 !important;
}

.about {
  max-width: 800px;
}

h1 {
  font-size: 2.86rem;
  line-height: 48px;
  margin-bottom: 8px;
  margin-top: 24px;
  font-family: staatliches;
  font-weight: 400;
  color: #E6E6E6;
  max-width: calc(100% - 75px);
}
@media screen and (min-width: 550px) {
  h1 {
    font-size: 3.16rem;
    line-height: 48px;
    margin-top: 0px;
  }
}

h2 {
  font-size: 1.43rem;
  line-height: 24px;
  color: #E6E6E6;
  font-weight: 400;
}

.description {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 200;
  margin-top: 16px;
}
.description p {
  margin-top: 8px;
}
.description a {
  text-decoration: underline;
}
@media screen and (min-width: 550px) {
  .description {
    font-size: 1.1428rem;
    line-height: 24px;
  }
}

.contact {
  width: 288px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact img:hover {
  transform: scale(1.05);
  transition: all 0.2s;
  filter: brightness(160%);
}

.project, .lab, .publication {
  min-height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding-left: 0;
}
@media screen and (min-width: 550px) {
  .project, .lab, .publication {
    padding-left: 120px;
  }
}
@media screen and (min-width: 1100px) {
  .project, .lab, .publication {
    padding-right: 40px;
  }
  .project:after, .lab:after, .publication:after {
    content: attr(data-project-name) " ";
    display: block;
    z-index: -1;
    position: absolute;
    color: #ffffff15;
    font-family: staatliches;
    font-size: 156px;
    line-height: 156px;
    width: calc(100vh - 144px);
    overflow: hidden;
    text-overflow: clip;
    bottom: 16px;
    right: -32px;
    transform-origin: bottom left;
    transform: translateX(calc(100vh - 144px)) rotateZ(-90deg);
  }
}
.project h3, .lab h3, .publication h3 {
  margin-top: 24px;
}
@media screen and (min-width: 990px) {
  .project h3, .lab h3, .publication h3 {
    margin-top: 0;
  }
}
.project h3:after, .lab h3:after, .publication h3:after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #38d6db;
  display: block;
}
.project a, .lab a, .publication a {
  color: #38d6db !important;
  transition: all 0.2s;
}
.project a:hover, .lab a:hover, .publication a:hover {
  transition: all 0.2s;
  filter: saturate(75%);
}

.preview {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 40px 0;
}
@media screen and (min-width: 990px) {
  .preview {
    flex-direction: row;
  }
}

.previewText {
  width: 100%;
}
@media screen and (min-width: 990px) {
  .previewText {
    max-width: 34%;
    padding-right: 40px;
  }
}

h3 {
  font-size: 2.86rem;
  line-height: 48px;
  font-family: staatliches;
  font-weight: 400;
  color: #E6E6E6;
  position: relative;
  display: inline-block;
}

.summary {
  margin-top: 16px;
}

h4 {
  margin-top: 24px;
  font-size: 1.43rem;
  line-height: 24px;
  color: #E6E6E6;
  font-family: staatliches;
  font-weight: 400;
}

.moreInfo {
  display: flex;
}
.moreInfo li {
  margin-top: 4px;
}

.partners {
  margin-left: 40px;
}

.moreBtn {
  display: block;
  margin-top: 8px;
  font-size: 1.1428rem;
  line-height: 24px;
}

.vimeo-wrapper {
  width: 100%;
  padding-top: calc(100% * 0.565);
  position: relative;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 10px -3px;
}
@media screen and (min-width: 990px) {
  .vimeo-wrapper {
    width: 66%;
    padding-top: calc(66% * 0.565);
  }
}

.vimeo-wrapper iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.previewImg {
  width: 100%;
  position: relative;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 10px -3px;
}
@media screen and (min-width: 990px) {
  .previewImg {
    width: 66%;
  }
}

.insta-wrapper {
  width: 100%;
  position: relative;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 10px -3px;
}
@media screen and (min-width: 990px) {
  .insta-wrapper {
    width: 66%;
  }
}

.lab h3:after {
  background-color: #c60feb;
}
.lab a {
  color: #c60feb !important;
}

.publication h3:after {
  background-color: #2AE094;
}
.publication a {
  color: #2AE094 !important;
}

.videoBGContainer {
  position: absolute;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.videoBG {
  background: black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;
  z-index: -1;
  filter: opacity(0.4);
}