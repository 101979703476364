@font-face
    font-family: lato
    font-weight: 200
    src: url(../fonts/Lato-Light.ttf)

@font-face
    font-family: lato
    font-weight: 400
    src: url(../fonts/Lato-Medium.ttf)
@font-face
    font-family: staatliches
    font-weight: 400
    src: url(../fonts/Staatliches-Regular.ttf)

html
    box-sizing: border-box
    font-size: 14px

*,*:before,*:after
    box-sizing: inherit
    margin: 0
    padding: 0

a, a:hover, a:visited
    cursor: pointer
    color: inherit
    text-decoration: inherit
ul
    list-style: none

button:focus
    outline: 0

$unselected: #7f7f7f
$selected: #E6E6E6
$body: #acacac
$titles: #E6E6E6
// $selected_invert: #ffffff
$project: #38d6db
$lab: #c60feb
$tools: #2a88e0
$publications: #2AE094

$background: #131313

$breakS: 550px
$breakM: 990px
$breakL: 1100px

$type-scale: (-1: 0.71rem, 0: 1rem, 1: 1.1428rem, 2: 1.43rem, 3 : 2rem, 4: 2.86rem)

@function type-scale($level) 
    @return map-get($type-scale, $level)


$base-font-size: 1rem
$base-line-height: 16px

$line-heights: (-1: $base-line-height, 0: $base-line-height*1.25, 1: $base-line-height*1.5, 2: $base-line-height * 1.5, 3:$base-line-height*2, 4: $base-line-height * 3)

@function line-height($level)
    @return map-get($line-heights, $level)

@mixin type-setting($level: 0)
    font-size: type-scale($level)
    line-height: line-height($level)

@mixin mQ($arg...)
    @if length($arg) == 1
        @media screen and (min-width: nth($arg, 1))
            @content
    @if length($arg) == 2
        @media screen and (min-width: nth($arg, 1)) and (max-width: nth($arg, 2))
            @content
