@use "_base.pack.sass" as *

body
    padding: 0 16px
    background: $background
    color: $body
    font-family: lato
    font-weight: 400
    @include type-setting(0)
    overflow-x: hidden
    @include mQ($breakS)
        padding: 0 32px
header
    min-height: 100vh
    // min-height: 00px
    display: flex
    align-items: center
    width: 100%
    position: relative

    @include mQ($breakS)
        padding-left: 120px
        padding-right: $base-line-height
    @include mQ($breakL)
        &:after
            content: "Hey !"
            display: block
            z-index: -1
            position: absolute
            color: #ffffff15
            font-family: staatliches
            font-size: 156px
            line-height: 156px
            width: calc(100vh - 144px)
            overflow: hidden
            text-overflow: clip
            bottom: $base-line-height
            right: -$base-line-height*2
            transform-origin: bottom left
            transform: translateX(calc(100vh - 144px)) rotateZ(-90deg)
    // @include mQ(calc(700px + 464px + 120px + 24px + 24px))
    //     padding-left: calc(128px + (100vw - 700px - 464px - 120px - 24px ) / 2)

// NAV
nav
    cursor: pointer
    position: fixed
    z-index: 100
    bottom: 0
    left: 0
    width: 100%
    padding: 12px 16px
    font-family: staatliches
    letter-spacing: 1px
    font-weight: 400
    @include type-setting(1)
    color: $unselected
    box-shadow: 0 -1px 5px 0 #00000015
    transition: all 0.15s
    background-color: black
    
    ul
        display: flex
        justify-content: space-between
        align-content: center
    
    @include mQ($breakS)
        padding: 0
        top: 0
        left: 0
        bottom: auto
        margin: $base-line-height 0 0 $base-line-height
        width: 100px
        height: auto
        border-radius: 0
        background-color: transparent
        box-shadow: none

        ul
            display: block
.nav_el
    position: relative
    display: block
    // padding: 0 8px 0 8px
    transition: font-size 0.2s ease-in-out, font-weight 0.2s ease-in-out, color 0.2s
    

    @include mQ($breakS)

        padding: 0
        &:hover
            color: $selected
        &:not(:first-child)
            margin-top: $base-line-height/3
.nav_el.selected
    display: block
   
    // font-weight: 800
    color: $selected

    @include mQ($breakS)
         @include type-setting(2)


.langChoice
    position: absolute
    top: 0
    right: 0
    z-index: 3
    @include type-setting(2)
    padding: 24px 16px 0 0
    @include mQ($breakS)
        padding: 24px 32px 0 0
.langChoice_el
    font-family: staatliches
    letter-spacing: 1px
    font-weight: 400
    color: $unselected !important
.langChoice_el.selected
    color: $selected !important

// ABOUT
.about
    max-width: 800px
    
h1
    @include type-setting(4)
    margin-bottom: 8px
    margin-top: 24px
    font-family: staatliches
    font-weight: 400
    color: $titles
    max-width: calc(100% - 75px)
    @include mQ($breakS)
        font-size: 3.16rem
        line-height: $base-line-height*3
        margin-top: 0px

    
h2
    @include type-setting(2)
    color: $titles
    font-weight: 400

.description
    @include type-setting(0)
    font-weight: 200
    margin-top: 16px
    p
        margin-top: 8px
    a
        text-decoration: underline
    @include mQ($breakS)
        @include type-setting(1)

.contact
    width: 288px
    margin-top: $base-line-height
    display: flex
    justify-content: space-between
    align-items: center
.contact img:hover
    transform: scale(1.05)
    transition: all 0.2s
    filter: brightness(160%)
    


// Next sections

.project, .lab, .publication
    min-height: 100vh
    // min-height: 600px
    display: flex
    align-items: center
    width: 100%
    position: relative
    padding-left: 0
    @include mQ($breakS)
        padding-left: 120px
       
    @include mQ($breakL)
        padding-right: 40px
        &:after
            content: attr(data-project-name) " "
            display: block
            z-index: -1
            position: absolute
            color: #ffffff15
            font-family: staatliches
            font-size: 156px
            line-height: 156px
            width: calc(100vh - 144px)
            overflow: hidden
            text-overflow: clip
            bottom: $base-line-height
            right: -$base-line-height*2
            transform-origin: bottom left
            transform: translateX(calc(100vh - 144px)) rotateZ(-90deg)
    h3
        margin-top: 24px
        @include mQ($breakM)
            margin-top: 0
    h3:after
        content: ""
        width: 100%
        height: 2px
        position: absolute
        left: 0
        bottom: 0
        background-color: $project
        display: block

    a
        color: $project !important
        transition: all 0.2s

    a:hover
        transition: all 0.2s
        filter: saturate(75%)

.preview
    width: 100%
    display: flex

    flex-direction: column-reverse
    align-items: center
    margin: 40px 0

    @include mQ($breakM)
        flex-direction: row

.previewText
    width: 100%
    @include mQ($breakM)
        max-width: 34%
        padding-right: 40px

h3
    @include type-setting(4)
    font-family: staatliches
    font-weight: 400
    color: $titles
    position: relative
    display: inline-block
.summary
    margin-top: 16px
h4
    margin-top: 24px
    @include type-setting(2)
    color: $titles
    font-family: staatliches
    font-weight: 400
.moreInfo
    display: flex

    li 
        margin-top: 4px
.partners
    margin-left: 40px
.moreBtn
    display: block
    margin-top: 8px
    @include type-setting(1)
    
.vimeo-wrapper
    width: 100%
    padding-top: calc(100%  * 0.565)
    position: relative
    background-color: black
    box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 10px -3px
    
    @include mQ($breakM)
        width: 66%
        padding-top: calc(66%  * 0.565)
    
.vimeo-wrapper iframe 
    position: absolute
    top: 50%
    left: 50%
    width: 100%
    height: 100%
    transform: translate(-50%, -50%)

.previewImg
    width: 100%
    position: relative
    background-color: black
    box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 10px -3px

    @include mQ($breakM)
        width: 66%

.insta-wrapper
    width: 100%
    position: relative
    background-color: black
    box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 10px -3px
    
    @include mQ($breakM)
        width: 66%

.lab
    h3:after
        background-color: $lab

    a
        color: $lab !important
    
.publication
    
    h3:after
       
        background-color: $publications
    a
        color: $publications !important

.videoBGContainer
    position: absolute
    overflow-x: hidden
    width: 100vw
    height: 100vh
    left: 0
    top:0

.videoBG 
    background: black
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    min-height: 100%
    min-width: 100%
    z-index: -1
    filter: opacity(0.4)